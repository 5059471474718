// TOP PAGE //
import React, { useEffect, useState } from 'react';
import { Link } from "gatsby";
import { Emoji, unescapeProcess } from '../components/Utils';
import { getMonacardInfo } from "../components/Mpurse";
import Seo from '../components/Seo';
import { MonapaImage } from "../components/MonapaImage";
import { LightningWidget } from "../components/LightningWidget";
import { ITEMS_SPACE } from '../components/InitItemsSpace';

const IndexPage = () => {
  const [ memorialCardsInfo, setMemorialCardsInfo ] = useState([]);
  const ASKMONA_LINK = "https://web3.askmona.org/154";
  const MONANOSU_CARDS_NAME = "MONANOSU.04,MONANOSU.07,MONANOSU.08,MONANOSU.09,MONANOSU.10,MONANOSU.11,MONANOSU.12,MONANOSU.13,MONANOSU.14,MONANOSU.15,MONANOSU.16";

  useEffect(() => {
    getMemorialCardsObj();
    setTimeout(() => {
      const element = document.getElementById("canvas");
      if(element != null) { element.remove() }
    }, 100);
    
    // if(typeof window !== `undefined`){ window.addEventListener("scroll", slideIn) }
  }, []);

  async function getMemorialCardsObj() {
    const cardInfoArray = [];
    const cardInfoHTML = [];
    const cardInfo: Array<any> = await getMonacardInfo(MONANOSU_CARDS_NAME);
    cardInfo.forEach((r) => {
      cardInfoArray.push(
        {
          assetName: r.asset_longname === null ? r.asset_common_name: r.asset_longname,
          assetgroup: r.assetgroup,
          card_name: unescapeProcess(r.card_name),
          owner_name: unescapeProcess(r.owner_name),
          imgur_url: unescapeProcess(r.imgur_url),
          add_description: unescapeProcess(r.add_description),
          tw_id: unescapeProcess(r.tw_id),
          tw_name: unescapeProcess(r.tw_name),
          tag: unescapeProcess(r.tag),
          cid: r.cid,
          ver: r.ver,
          is_good_status: r.asseis_good_statustgroup,
          regist_time: r.regist_time,
          update_time: r.update_time,
        }
      );
    });

    cardInfoArray.forEach((r) => {
      const CARD_URL_ASSETINFO = "https://card.mona.jp/explorer/card_detail?asset=" + r.assetName;
      const CARD_URL_IMAGE = ITEMS_SPACE.url.URL_IMG_DATA + r.cid;
      
      const isRotate = r.add_description.includes("OPT_ROT");
      if(isRotate) {
        cardInfoHTML.push(
          <div key={ r.assetName } className="p-10 flex border-solid border-2 border-gray-900 rounded-tl-[48px] rounded-br-[48px]">
            <div className="px-12 flex justify-center items-center">
              <a href={ CARD_URL_ASSETINFO } target="_blank">
                <img className="origin-center -rotate-90 w-48 transition duration-[500ms] ease-in-out hover:scale-110" src={ CARD_URL_IMAGE } />
              </a>
            </div>
            <div className="p-2">
              <div className="p-2">
                <p className="font-bold underline italic">Asset Name</p>
                <p>{ r.assetName }</p>
              </div>
              <div className="p-2">
                <p className="font-bold underline italic">Card Name</p>
                <p>{ r.card_name }</p>
              </div>
              <div className="p-2">
                <p className="font-bold underline italic">Description</p>
                <p>{ r.add_description }</p>
              </div>
              <div className="p-2">
                <p className="font-bold underline italic">CID</p>
                <p>{ r.cid }</p>
              </div>
            </div>
          </div>
        );
        return (
          <div className="px-4 flex justify-center items-center">
            <img className="origin-center -rotate-90 w-3/4" src={ CARD_URL_IMAGE } />
          </div>
        );
      } else {
        cardInfoHTML.push(
          <div key={ r.assetName } className="p-10 flex border-solid border-2 border-gray-900 rounded-tl-[48px] rounded-br-[48px]">
            <a href={ CARD_URL_ASSETINFO } target="_blank">
              <img className="w-48 transition duration-[500ms] ease-in-out hover:scale-110" src={ CARD_URL_IMAGE } />
            </a>
            <div className="p-2">
              <div className="p-2">
                <p className="font-bold underline">Asset Name</p>
                <p>{ r.assetName }</p>
              </div>
              <div className="p-2">
                <p className="font-bold underline">Card Name</p>
                <p>{ r.card_name }</p>
              </div>
              <div className="p-2">
                <p className="font-bold underline">Description</p>
                <p>{ r.add_description }</p>
              </div>
              <div className="p-2">
                <p className="font-bold underline">CID</p>
                <p>{ r.cid }</p>
              </div>
            </div>
          </div>
        );
      }
    });
    setMemorialCardsInfo(cardInfoHTML);
  }

  function slideIn() {
    const slideInDiv_1 = document.getElementById("slide_in_01");
    const slideInDiv_2 = document.getElementById("slide_in_02");
    const slideInDiv_3 = document.getElementById("slide_in_03");
    if(!slideInDiv_1) return;
    const slideInDivPosition_1 = slideInDiv_1.getBoundingClientRect().top;
    const slideInDivPosition_3 = slideInDiv_3.getBoundingClientRect().top;
    const windowHeight = window.innerHeight;

    if(slideInDivPosition_1 - windowHeight <= 0) {
      slideInDiv_1.style.opacity = "1";
      slideInDiv_2.style.opacity = "1";
      slideInDiv_1.style.transform = "translateX(0)";
      slideInDiv_2.style.transform = "translateX(0)";
    }

    if(slideInDivPosition_3 - windowHeight <= 0) {
      slideInDiv_3.style.opacity = "1";
      slideInDiv_3.style.transform = "translateX(0)";
    }
  }

  return(
    <>
      <Seo />
      <div id="menuField" className="text-center text-gray-900 flex justify-center content-center">
        <div className="w-full h-fit px-4 sm:px-8 py-12">
          <div>
            <div className="h-screen flex justify-center items-center">
              <p className="font-light italic text-4xl sm:text-5xl">monanosu</p>
            </div>
            <div className="py-2 sm:grid sm:grid-cols-2 sm:gap-8">
              {/* <div id="slide_in_01" className="opacity-0 relative -translate-x-96 transition ease-in duration-1000"> */}
              <div id="slide_in_01" className="">
                <Link to="island">
                  <div className="py-24 bg-white border-solid border-2 border-gray-900 rounded-tl-[48px] rounded-br-[48px] mt-8 transition duration-[250ms] ease-out hover:opacity-30 hover:invert overflow-hidden">
                    <p className="text-2xl sm:text-3xl">monanosu island
                      <span className="text-5xl">  { Emoji.fujisannEmoji }</span>
                    </p>
                    <div className="mt-20 px-8 sm:px-8">
                      <p className="">DecaMona grows as the number of TX on Monaparty increases. Every day.</p>
                      <p className="mt-4">To be precise, it increases the number of MonapartyTX by dividing it by 4. If the growth rate increases, it may change in the future.</p>
                      <p className="mt-10">デカモナーはモナパーティーのトランザクションに応じて毎日成長しています。みんながモナパーティーで遊ぶことによってドンドコ成長します。</p>
                      <p className="mt-4">（正確にはMonapartyTXを4で割った数値が反映されています。これは将来的には変更される可能性があります。）</p>
                    </div>
                    <div className="mt-20">
                      <p className="font-light italic text-4xl sm:text-5xl underline">Visit</p>
                    </div>
                  </div>
                </Link>
              </div>
              {/* <div id="slide_in_02" className="opacity-0 relative translate-x-96 transition ease-in duration-1000"> */}
              <div id="slide_in_02" className="">
                <Link to="space">
                  <div className="py-24 bg-white border-solid border-2 border-gray-900 rounded-tl-[48px] rounded-br-[48px] mt-8 transition duration-[250ms] ease-out hover:opacity-30 hover:invert overflow-hidden">
                    <p className="text-2xl sm:text-3xl">monanosu space
                      <span className="text-5xl">  { Emoji.musiumEmoji }</span>
                    </p>
                    <div className="mt-20 px-8 sm:px-8">
                      <p className="">You can display Monacard.</p>
                      <p className="mt-10">モナカードを飾ることが出来ます。</p>
                    </div>
                    <div className="mt-20">
                      <p className="font-light italic text-4xl sm:text-5xl underline">Visit</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="h-screen flex justify-center items-center">
              <p className="mt-32 mb-4 font-light italic text-4xl sm:text-5xl flex justify-center content-center">goods</p>
            </div>
            <div className="py-16 border-solid border-2 border-gray-900 rounded-tl-[48px] rounded-br-[48px] text-sm text-left">
              <div className="px-12">
                <p className="text-2xl sm:text-3xl">DecaMona Memorial Cards
                  <span className="text-5xl">{ Emoji.gemEmoji }</span>
                </p>
                <div className="">
                  <p className="">Memorial cards are issued and sold as DecaMona grows.</p>
                  <p className="mt-2">デカモナーの成長に合わせて記念カードの発行と販売をしています。</p>
                </div>
              </div>
              <div className="mt-20 pl-6 pr-0 overflow-x-scroll grid grid-flow-col auto-cols-max gap-8">
                { memorialCardsInfo }
              </div>
              <div className="px-12">
                <div className="mt-20 w-full h-40 bg-white border-solid border-2 rounded-tl-[24px] rounded-br-[24px] border-gray-900 transition duration-[250ms] ease-in-out hover:invert hover:opacity-30">
                  <a className="w-full h-full flex justify-center " href="https://monapalette.komikikaku.com/shops/4713057409_CF76FDE4" target={ "_blank" }>
                    <p className="mt-16 sm:mt-14 font-light italic text-4xl sm:text-5xl">.,/ Shop /`~</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="mt-32 px-12 py-16 border-solid border-2 border-gray-900 rounded-tl-[48px] rounded-br-[48px] text-sm text-left">
              <p className="">Please send any problems to Twitter and AskMona3.0.</p>
              <p className="">AskMona3.0 is <a className="underline" href={ ASKMONA_LINK }>HERE.</a></p>
              <p className="mt-4">不具合等はTwitter, AskMona3.0へお願いします。</p>
              <p className="">AskMona3.0は<a className="underline" href={ ASKMONA_LINK }>こちら。</a></p>
            </div>
            <div className="my-24 flex justify-center">
              <div className="w-1/2 sm:w-1/3">
                <a href="https://monapalogo.kotaroo.work/">
                  <MonapaImage />
                </a>
              </div>
            </div>
            <div className="text-xs mt-12 text-zinc-600">
              <p className="text-left border-b-2">DONATE</p>
              <div className="py-4 sm:p-8 w-full text-left mb-4 break-all">
                <div className="w-full">
                  <div>
                    <p className="p-1 ">{ Emoji.fujisannEmoji } mona / monacard</p>
                    <p className="p-1 ">MCVRBQEnDrPVzqYVkchLrPGvLpHG9AhL1r</p>
                  </div>
                  <div>
                    <p className="p-1 ">{ Emoji.fujisannEmoji } btc</p>
                    <p className="p-1 ">bc1qpz64zqhcp2gfm8xyhxct39pmhpwcf7xa2xfh9n</p>
                  </div>
                  <div>
                    <p className="p-1 ">{ Emoji.fujisannEmoji } eth</p>
                    <p className="p-1 ">0xf6721444dB4ADf853FA48b5b0AD68c73e31996b5</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center content-center mb-8">
              <p className="text-xl">{Emoji.kaminariEmoji}</p>
              <LightningWidget />
              <p className="text-xl">{Emoji.kaminariEmoji}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IndexPage;