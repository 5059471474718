import React from 'react';
import { StaticImage } from "gatsby-plugin-image"

export function MonapaImage() {
  let imagaNum = Math.floor( Math.random() * 9);
  const image = {
    0: <div><StaticImage src="../images/usagi_02.png" alt="MonapartyFreeLogo" /></div>,
    1: <div><StaticImage src="../images/gem_01.png" alt="MonapartyFreeLogo" /></div>,
    2: <div><StaticImage src="../images/gem_04.png" alt="MonapartyFreeLogo" /></div>,
    3: <div><StaticImage src="../images/izuru_01.png" alt="MonapartyFreeLogo" /></div>,
    4: <div><StaticImage src="../images/kate_01.png" alt="MonapartyFreeLogo" /></div>,
    5: <div><StaticImage src="../images/kate_03.png" alt="MonapartyFreeLogo" /></div>,
    6: <div><StaticImage src="../images/kotori_01.png" alt="MonapartyFreeLogo" /></div>,
    7: <div><StaticImage src="../images/mekabu_01.png" alt="MonapartyFreeLogo" /></div>,
    8: <div><StaticImage src="../images/neszta_01.png" alt="MonapartyFreeLogo" /></div>,
  }
  return image[imagaNum];
}
